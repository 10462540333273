<template>
  <div class="min-h-screen flex flex-col">
    <Header msg="Find free legal help"/>
    <div class="main-content relative flex flex-grow items-start container m-auto mb-50 p-[15px] mb-[15px]">
      <!--  /* min-h-[calc(100vh-200px)]*/ old height hack...-->
      <!-- <LandingMessage msg="Welcome to the CLCNSW Find Legal Help App"/> -->

<!-- THis mess is compiling and working but... probably not doing what I think it is... -->
      <suspense>
      <router-view/>  <!-- views get injected here. -->
      </suspense>
      
      <router-view v-slot="{ AllServices }">
        <template v-if="AllServices">
            <keep-alive>
              <suspense >
                <component :is="AllServices"></component>
                <template #fallback>
                  <div>
                    Loading...
                  </div>
                </template>
              </suspense>
            </keep-alive>
        </template>
      </router-view>

    </div>
    <Footer msg="GET LEGAL HELP!" /> 
  </div>
</template>


<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
//import LandingMessage from './components/LandingMessage.vue'
//import ServiceSearch from './components/ServiceSearch.vue'
//import LandingPage from './views/LandingPage.vue'
//import AllServices from './components/AllServices.vue'
//import NotFound from './components/NotFound.vue'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'LUCY - Find Legal Help',
  components: {
    Header,
    //LandingPage,
    Footer,
    //LandingMessage
    AllServices: defineAsyncComponent(() => import('./views/AllServices.vue')),
    ServiceSearch: defineAsyncComponent(() => import('./views/ServiceSearch.vue')),
  },
  prop: {
    msg: String
  },
  /* router code */
  data() {
    return {
    }
  },
}
</script>

<style>
@font-face {
  font-family: "RobotoSlab";
  src: local("RobotoSlab"),
  url("assets/fonts/robotoslab/RobotoSlab-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Black";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Black Italic";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Bold";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Bold Italic";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Extra Light";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Extra Light Italic";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-ExtraLightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Italic";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Light";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Light Italic";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro Regular";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro SemiBold";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro SemiBold Italic";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("sourcesanspro"), url("assets/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.ttf") format("truetype");
}
/* app and body base css */

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
/*   text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
  /* background-color: #00A1D4; */
  background: rgba(0, 161, 212, 0.2);
  
}
body {
  font-size: 16px;
}
/* app-wide button stuff */
.btn {
  @apply font-bold py-2 px-4 rounded;
}
.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}


.clcnsw-heading {
  font-family: "Source Sans Pro SemiBold", Arial, Helvetica, sans-serif;
/*   font-size: 45pt; */
  color: black;
  /* letter-spacing: 54pt; */
 /*  line-height: 1.2; */
  font-size: 2.25rem; /* 36px  4xl tailwind sizing...*/
  line-height: 2.5rem; /* 40px */
}
.main-content {
    /* min-height: 500px;*/
    /* margin-bottom: 100px; */
}
.main-content p {
  font-family: "Source Sans Pro Regular", Arial, Helvetica, sans-serif;
/*   font-size: 35pt;
  line-height: 1.2; */
  color: black;
}
button {
  font-family: "Source Sans Pro SemiBold", Arial, Helvetica, sans-serif !important;
/*   font-size: 30pt !important; */
  color: #fff !important;
}
.accordion-button p {
  font-family: 'Source Sans Pro Regular', Arial, Helvetica, sans-serif;
/*   font-size: 30pt;
  line-height: 1.2; */
}
.accordion-body {
    font-family: 'Source Sans Pro Regular', Arial, Helvetica, sans-serif;
    line-height: 1.2;
    color: black;
}
/* npt sure why I need to add this... something is wrong with my tailwind accordion... */
.collapse.show {
  visibility: visible;
}

/* print stuff... */
.show-for-print {
  /* hide by default */
  display: none;
}
@media print {
  .show-for-print {
    /* shw when printing */
    display: block;
  }
  .hide-for-print {
    display: none;
  }
}
</style>












