<template>
  <div class="message w-8/9 text-left text-xl">
    <h1 class='clcnsw-heading mb-5'>{{ title }}</h1>
    <p class="mb-5"> {{ greeting }} </p>
    <p class="mb-5"> {{ usage_massage }} </p>
    <p class="mb-5"> {{ more_help }} </p>
    <p class="mb-5"> View our <router-link to="/privacy">terms of use and privacy policy</router-link>.</p>
      
    <div class="flex justify-end mt-5">
      <router-link to="/service-search" custom v-slot="{ navigate }">
        <button class="btn btn-blue m-2 text-xl sm:text-3xl" @click="navigate" @keypress.enter="navigate" role="link">Start</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingMessage',
  data() {
    return {
      title: "Community legal centres provide free legal help to people in need.",
      greeting: "We are independent, confidential and not part of the government",
      usage_massage: "We will ask you a few questions and, if possible, match you with a community legal centre that may be able to help you. You can then contact the centre directly for assistance.",
      more_help: "We also suggest calling LawAccess NSW on 1300 888 529. This is a free government telephone service that provides legal information, advice and referals for people who have a legal problem in NSW.",
    }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .btn {
    @apply font-bold py-2 px-4 rounded;
  }
  .btn-blue {
    @apply bg-blue-500 text-white;
  }
  .btn-blue:hover {
    @apply bg-blue-700;
  }

</style>
