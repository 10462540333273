<template>
  <div class='services w-full text-xl'>
    <!--<h1>All Legal services</h1>
    <h1>URL: [ {{ services_url }} ] </h1> -->
    <p v-if="isLoadingList">
      Loading all services...
    </p>
    <template v-else>
      <div class='hide-for-print results accordion accordion-flush pt-5' id="accordionFlushExample">
        <!-- Pass individual object to a ServiceCard -->
        <ServiceCard v-for="service in rankedServices" :key="service" :service="service" />
      </div>
      <!-- Printable area -->
      <div class="show-for-print">
        <PrintView :search_result="rankedServices" />
      </div>

      <div class="flex justify-end mt-5 mb-5">
        <button class="btn btn-blue m-2 text-xl sm:text-3xl hide-for-print" @click="printResult()">Print results</button>
      </div>
    </template>

  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
//import { ref, onMounted } from 'vue'
import SETTINGS from '../env.config';
import ServiceCard from '../components/ServiceCard.vue';
import PrintView from '../components/PrintView.vue';


export default {
  components: {
    ServiceCard,
    PrintView
  },
/*   setup () {
    const isLoadingList = ref(false);

    onMounted (async () => {
      isLoadingList.value = true;
      try {

      } finally {

      }
    })
  }, */
  data() {
    return {
      services_url: SETTINGS.SERVICES_BASE_URL,
      questions_url: SETTINGS.QUESTIONS_BASE_URL,
      services: [],
      isLoadingList: Boolean
    }
  },
  mounted() {
    this.isLoadingList = true;
    try {
    axios
      .get(SETTINGS.SERVICES_BASE_URL)
      .then((response)=> {
        console.log(response.status)
        this.services = response.data
      })
    } finally {
      this.isLoadingList = false;
    }
  },
  computed: {
    rankedServices: function () {
      //try {
        const myList = _.orderBy(this.services, 'service_name', 'asc')
      // } finally {
        //this.upDateLoading();
        return myList;
      //}  
    }


  },
  methods: {
    /*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
/*     generateResultsPDF () {
        this.$refs.html2Pdf.generatePdf()
    }, */
    printResult() {
      /* force a print window and hide unwanted content. Must be reproduced on all service page */
      document.title='Community legal services';
      window.print();
    },
    upDateLoading() {
      this.isLoadingList = false;
    }
  } 
}
</script>

<style scoped>
/**  
.results {
  text-align: left;
  max-width: 1240px;
  margin: auto;
}
.services {
  text-align: left;
  max-width: 1240px;
  margin: auto;
  margin-bottom: 20px;
}
*/
</style>