<template>    
    <div class="show-for-print">
        <div class="print-message w-full">
            <p class="text-xl">Printed on: {{ currentDate() }}</p>
            <p class="text-xl mb-5 mt-5 font-bold">
                Community legal centres provide free legal help to people in need. 
                We are independent, confidential, and not part of the government.
            </p>
        </div>
        <div class="print-area show-for-print">
            <PrintViewServiceCard v-for="service in rankedServices" :key="service" :service="service" />
        </div>


        <div class="print-disclaimer mt-10 mb-10 print:break-inside-avoid">
                <h1 class="text-2xl font-bold mb-5">Need further help?</h1>
                <p class="text-base mb-5">
                    LawAccess NSW is a free government telephone service that provides legal information, advice and referrals for people who have a legal problem in NSW. 
                    LawAccess NSW can refer you to the community legal centre that's most appropriate for your legal issue.
                </p>
                <ul class="text-base mb-5">
                    <li class="text-base">Phone: 1300 888 529 </li>
                    <li class="text-base">Website: <a href="https://www.lawaccess.nsw.gov.au" >https://www.lawaccess.nsw.gov.au</a> </li>
                    <li class="text-base">Hours: 9am to 5pm, Monday to Friday. </li>
                </ul>
                <h1 class="text-2xl font-bold mb-5">Disclaimer</h1>
                <p class="text-base mb-5">
                    Community Legal Centres NSW's Find Legal Help application is designed to help you find the right community legal centre to contact. These results do not guarantee eligibility for the recommended services. 
                    The relevant legal services will be able to assess your eligibility and provide further information.  
                    These recommendationsare for general information purposes onlyand are not intended as legal advice.
                </p>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

import PrintViewServiceCard from "../components/PrintViewServiceCard.vue";

export default {
    name: "print-view",
    components: {
        PrintViewServiceCard
    },
    props: {
        search_result: Object,
        search_query: Object
    },
    methods: {
        currentDate() {
            const current = new Date();
            const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
            return date;
        }
    },
    computed: {
        rankedServices: function () {
            // TODO: order and split into two groups.  Above and below rank 5. 
            return _.orderBy(this.search_result, 'rank', 'desc')
        }
    }

}
</script>