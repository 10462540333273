<template>
    <!-- Tailindcss style flush accordian Represents a single service as an accordian -->
    <div class="bg-white mb-2 border-b-1 print:break-inside-avoid">
        <div class="mb-0" v-bind:id="'heading-' + service.id">
            <div>
                <h1 class="text-2xl text-black clcnsw-heading">
                    {{ service.service_name }}     
                </h1>
                <template v-if="service.is_hide_parent === false">
                    <p class="text-base"> {{ service.parent_clc_name }} </p>
                </template>
            </div>
        </div>
        <div class="border-0">
            <div class="py-1 text-left overflow-hidden">
                <div class="mb-2"  v-if="service.description">
                        <p class="text-base">{{service.description}}</p>
                </div>

                <!-- <div v-if="service.demographic_note" class="mb-2">
                    <span v-html="service.demographic_note"></span>
                </div> -->

<!--                 <div class="postcodes">
                    <p><strong>Postcodes covered by this service</strong></p>
                    <div class="my-grid flex flex-wrap">
                        <p class="flex-1 mr-2" v-for="pcode in service.postcodes" :key="pcode">{{ pcode }}</p>
                    </div>
                </div> -->
                <!-- <div class="legal-areas mb-5">
                    <p><strong>Legal Services</strong></p>
                    <ul>
                        <li v-for="legal in service.legalAreas" :key="legal">{{ legal.legal_area }}</li>
                    </ul>
                </div> -->

                <!-- PHONE -->
                <div v-if="service.phone_number" class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/Phone.svg" alt="phone" /> 
                    </div>
                    <div class="icon-content text-sm">
                        {{ service.phone_number }}
                    </div>
                </div>

                <!-- EAMIL -->
                <div v-if="service.email" class="service-icon-wrapper flex flex-grid html-overrides">
                    <div class="icon">
                        <img src="@/assets/icons/Email.svg" alt="email" /> 
                    </div>
                    <div class="icon-content text-sm">
                        {{ service.email }}
                    </div>
                </div>

                <!-- WEBSITE -->
                <div v-if="service.url" class="service-icon-wrapper flex flex-grid html-overrides">
                    <div class="icon">
                        <img src="@/assets/icons/Website.svg" alt="website logo" />  
                    </div>
                    <div class="icon-content text-sm">
                        {{ service.url}}
                    </div>
                </div>

                <!-- DROP IN -->
<!--                 <div class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/location_or_address.svg" alt="location" />  
                    </div>
                    <div class="icon-content text-sm">
                        Drop in/ Location: TODO
                    </div>
                </div> -->

                <!-- OPENING HOURS-->
                <div v-if="service.demographic_note" class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/opening_hours.svg" alt="clock" />
                    </div>
                    <div class="icon-content text-sm">
                        <span v-html="service.demographic_note" class="styled-text html-overrides" ></span>
                    </div>
                </div>

                <!-- FACEBOOK-->
<!--                 <div class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/Facebook.svg" alt="facebook" />  
                    </div>
                    <div class="icon-content text-sm">
                        Facebook: TODO
                    </div>
                </div> -->

                <!-- ATSI CONTROLLED -->
                <div v-if="service.is_aboriginal_controlled" class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/aboriginal_flag.svg" alt="aboriginal-flag" />
                    </div>
                    <div class="icon-content text-sm">
                        Aboriginal-controlled organisation
                    </div>
                </div>
                <!-- Optional Notes below -->                    
                <div v-if="service.optional_notes" class="styled-text html-overrides text-sm" >
                    <span v-html="service.optional_notes"></span>
                </div>
                <div v-if="service.emergency_notes" class="styled-text html-overrides text-sm" >
                    <span v-html="service.emergency_notes"></span>
                </div>            
            
            </div><!-- end accordian body -->
        </div>
  </div>
</template>


<script>


export default {
    name: 'service-card',
    /* take in a single service object and render relevant elements */
    /* setup and create an accordian i basic js... really need to consider a framework. */
    props:{
        service: []
    }
}
</script>

<style scoped>
img {
    width: 100%;
    height: auto;
}
.service-icon-wrapper img {
    display: inline-block;
}
.service-icon-wrapper {
    width:100%;
    min-height: 25px;
    float:left;
}
.service-icon-wrapper .icon {
    width:25px;
    padding: 5px;
    float: left;
}
.service-icon-wrapper .icon-content {
    width:90%;
    padding: 10px;
    float: left;
}

</style>
<style>
.html-overrides a {
    color: blue;
}
</style>


