<template>
    <div class="w-[80%] m-auto p-5 text-xl progress-bar-wrapper">
        <div class="flex justify-evenly">
        <template v-if="step === 1" >
                <div>
                    <div class="w-full flex justify-center">
                        <div class="bg-active text-white rounded-full w-10 h-10 flex justify-center items-center">
                            <span>1</span>
                        </div>
                    </div>
                    <div class="text-lg text-center breadcrumb">
                        <p class="align-middle text-black pt-2">Legal Issue</p>
                    </div>
                </div>
                <div class="flex justify-center px-4 pt-4 w-[40%]">
                    <span class="border h-2 w-full align-middle">
                        &zwnj;
                    </span>
                </div>
                <div>
                    <div class="w-full flex justify-center">
                        <div class="p-5 text-black rounded-full border w-10 h-10 flex justify-center items-center">
                            <span>2</span>
                        </div>
                    </div>
                    <div class="text-lg text-center breadcrumb">
                        <p class="align-middle text-black pt-2">About you</p>
                    </div>
                </div>
                <div class="flex justify-center px-4 pt-4 w-[40%]">
                    <span class="border h-2 w-full align-middle">
                        &zwnj;
                    </span>
                </div>
                <div>
                    <div class="w-full flex justify-center">
                        <div class="border p-5 text-black rounded-full w-10 h-10 flex justify-center items-center">
                            <span>3</span>
                        </div>
                    </div>
                    <div class="text-lg text-center breadcrumb">
                        <p class="align-middle text-black pt-2">Results</p>
                    </div>
                </div>
        </template>
        <template v-if="step === 2" >
                <div>
                    <div class="w-full flex justify-center">
                        <div class="bg-active text-white rounded-full w-10 h-10 flex justify-center items-center">
                            <span>1</span>
                        </div>
                    </div>
                    <div class="text-lg text-center breadcrumb">
                        <p class="align-middle text-black pt-2">Legal Issue</p>
                    </div>
                </div>
                <div class="flex justify-center px-4 pt-4 w-[40%]">
                    <span class="bg-active border h-2 w-full align-middle">
                        &zwnj;
                    </span>
                </div>
                <div>
                    <div class="w-full flex justify-center">
                        <div class="bg-active p-5 text-black rounded-full border w-10 h-10 flex justify-center items-center">
                            <span>2</span>
                        </div>
                    </div>
                    <div class="text-lg text-center breadcrumb">
                        <p class="align-middle text-black pt-2">About you</p>
                    </div>
                </div>
                <div class="flex justify-center px-4 pt-4 w-[40%]">
                    <span class="border h-2 w-full align-middle">
                        &zwnj;
                    </span>
                </div>
                <div>
                    <div class="w-full flex justify-center">
                        <div class="border p-5 text-black rounded-full w-10 h-10 flex justify-center items-center">
                            <span>3</span>
                        </div>
                    </div>
                    <div class="text-lg text-center breadcrumb">
                        <p class="align-middle text-black pt-2">Results</p>
                    </div>
                </div>
        </template>
        <template v-if="step === 3" >
                <div>
                    <div class="w-full flex justify-center">
                        <div class="bg-active text-white rounded-full w-10 h-10 flex justify-center items-center">
                            <span>1</span>
                        </div>
                    </div>
                    <div class="text-lg text-center breadcrumb">
                        <p class="align-middle text-black pt-2">Legal Issue</p>
                    </div>
                </div>
                <div class="flex justify-center px-4 pt-4 w-[40%]">
                    <span class="bg-active border h-2 w-full align-middle">
                        &zwnj;
                    </span>
                </div>
                <div>
                    <div class="w-full flex justify-center">
                        <div class="bg-active p-5 text-black rounded-full border w-10 h-10 flex justify-center items-center">
                            <span>2</span>
                        </div>
                    </div>
                    <div class="text-lg text-center breadcrumb">
                        <p class="align-middle text-black pt-2">About you</p>
                    </div>
                </div>
                <div class="flex justify-center px-4 pt-4 w-[40%]">
                    <span class="bg-active border h-2 w-full align-middle">
                        &zwnj;
                    </span>
                </div>
                <div>
                    <div class="w-full flex justify-center">
                        <div class="bg-active border p-5 text-black rounded-full w-10 h-10 flex justify-center items-center">
                            <span>3</span>
                        </div>
                    </div>
                    <div class="text-lg text-center breadcrumb">
                        <p class="align-middle text-black pt-2">Results</p>
                    </div>
                </div>
        </template>
        </div>
    </div>
</template>

<script>
export default {
    /* Compenent shows progress of user in the search wizard. Takes "step" value fro parent */
    name: 'progess-bar',
    props:{
        step: {
            type: Number,
            required: false
        }
    }
}
</script>

<style scoped>
    .bg-active {
        background-color: rgb(0,161,212);
    }
    .bg-active p {
        color: white;
    }
    .progress-bar-wrapper * {
        border-color:  rgb(0,161,212);
        color: rgb(0,161,212);
    }
    .progress-bar-wrapper .bg-active span {
        color: white;
    }
    .breadcrumb p {
        color: black !important;
    }

</style>

