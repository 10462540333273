<template>
  <div class="service-search-wrapper w-full text-xl">
    <!-- <SearchPageOne /> -->
    <div class="progress-bar hide-for-print hide-for-print">
      <ProgressBar :step="step" />
    </div>
    <!-- debug info -->
    <div class="debug--bar justify-center p-5 hide-for-print hidden">
      <p class="hide-for-print">[ STEP: {{ step }} | Legal Problem: {{ search_query.legal_issue }}  |  Postcode: {{ myPCode }} | Demo: {{ search_query.demo_responses }} ]</p>
    </div>
    <!-- clean this up and do it in a better way than nested templates...-->
    <template v-if="step === 3">
      <div>
        <p  class="block tracking-wide text-gray-700 text-xl font-bold mb-1 text-center hide-for-print">Top matches for your search:</p>
      </div>
      <template v-if="legal_issue_display_name != '' | myPCode != '' | search_query.demo_responses != ''">
        <div class="search-info justify-center p-5 hide-for-print text-center">
          <p v-if="legal_issue_display_name != ''"  class="text-xl"><strong>Legal Issue:</strong> {{ legal_issue_display_name }}</p>
          <p v-if="myPCode != ''"  class="text-xl"><strong>Location:</strong>  {{ myLocality }} </p>
          <p v-if="search_query.demo_responses != ''"  class="text-xl"><strong>Specialist requests:</strong> {{ demoChoicesDisplayList }}</p>
        </div>
      </template>
    </template>
    <!-- important steps -->
    <keep-alive>
      <SearchPageOne 
        :legal_questions="questions.legal_questions" 
        :legal_query="legal_query" 
        v-model:legal_issue="search_query.legal_issue"
        v-model:legal_issue_name="legal_issue_display_name"
        :key="page_one_key"
        v-if="step === 1" />
    </keep-alive>
    <keep-alive>
        <SearchPageTwo 
          :demographic_questions="questions.demographic_questions" 
          v-model:postcode="search_query.postcode"
          v-model:demo_responses="search_query.demo_responses"
          :key="page_two_key"   
          v-if="step === 2" />
      </keep-alive>
        <SearchResults 
          :search_query="search_query"
          :search_result="search_result"
          v-if="step === 3" />
    
    <div class="flex justify-end mt-5 mb-5">
      <button class="btn btn-blue m-2 text-xl sm:text-3xl hide-for-print"  v-if="step === 3" @click="printResult()">Print results</button>
      <button class="btn btn-blue m-2 text-xl sm:text-3xl hide-for-print" v-if="step > 1 && step <4" @click="stepForm(-1)">Previous</button>
      <button class="btn btn-blue m-2 text-xl sm:text-3xl hide-for-print" v-if="step === 1" @click="stepForm(1)">Next</button>
      <button class="btn btn-blue m-2 text-xl sm:text-3xl hide-for-print" v-if="step === 2" @click="makeQuery" >Submit</button>
      <button class="btn btn-blue m-2 text-xl sm:text-3xl hide-for-print" v-if="step === 3" @click="restartSearch">Start Over</button>
    </div>
    <!-- Printable area -->
    <div class="show-for-print">
      <PrintView 
          :search_query="search_query"
          :search_result="search_result"
          v-if="step === 3" />
    </div>

  </div><!-- end wrapper -->
</template>

<script>
import axios from 'axios'
import SETTINGS from '../env.config'
import { ref, provide } from 'vue'
//import ServiceCard from '../components/ServiceCard.vue'
import ProgressBar from '../components/ProgressBar.vue'
import SearchPageOne from '../components/search-form/SearchPageOne.vue'
import SearchPageTwo from '../components/search-form/SearchPageTwo.vue'
import SearchResults from '../components/search-form/SearchResults.vue'
import PrintView from '../components/PrintView.vue'

export default {
  setup() {
    //try and provide a postcode value to decendant widget 
    const myPCode = ref(0)
    const myLocality = ref('')
    const setPostCode = value => {
      myPCode.value = value
    }
    const setLocality = value => {
      myLocality.value = value
    }

    provide('setPostCode', setPostCode)
    provide('setLocality', setLocality)

    return {
      myPCode,
      myLocality
    }
  },
  components: {
    //ServiceCard,
    SearchPageOne,
    SearchPageTwo,
    SearchResults,
    ProgressBar,
    PrintView,
  },
  data() {
    return {
      services_url: SETTINGS.SERVICES_BASE_URL,
      questions_url: SETTINGS.QUESTIONS_BASE_URL,
      questions: [],
      isActive: false,
      legal_query: [
        { "legal_area": "", "postcode": "", "demo_responses": [] }
      ],
      search_query: {
        legal_issue: "",
        postcode: "",
        demo_responses: []
      },
      legal_issue_display_name: "",
      service_list: [],
      step: 1,
      search_result: "",
      page_one_key: 0,
      page_two_key: 0
    }
  },
  async created() {
    // GET request using axios with async/await
    const response = await axios.get(SETTINGS.QUESTIONS_BASE_URL);
    this.questions = response.data;
    this.scrollToTop(); //try and start at the top
  },
  computed: {
    demoChoicesDisplayList: function() {
      var display_string = ""
      const myChoices = this.search_query.demo_responses;
      this.questions.demographic_questions.forEach(function (item) {
        if (myChoices.includes(item.f_value)) {
          display_string = display_string + ', ' + item.f_label;
        }
        //display_string = display_string + ', ' + item.f_label;
      });
      return display_string.substring(1);
    }
  },
  methods: {
    onSubmit(e){ 
        // TODO: REMOVE NO LONGER USED...  
        e.preventDefault()
        if(!this.legal_query.legal_area){
            alert('Please Add Legal problem!')
            return
        }
        const NewInformation = {
            legal_problem : this.legal_query.legal_area,
            postcode : this.legal_query.postcode
        }
        //this.$emit('add-information', newInformation)
        //this.name = ' ',
        //this.age = ' '
        alert(JSON.stringify(NewInformation));
    },
    submitSearch(data){
        /* this works as well.... can do either way */
        //data.preventDefault()
        // TODO: REMOVE NO LONGER USED... 
        console.log(data);
        if(!this.legal_query.legal_area){
            alert('Please Add Legal problem!')
            return
        }
        const payload = {
            "legal_area" : this.legal_query.legal_area,
            "postcode" : this.legal_query.postcode,
            "demographic_data": {
              "asti": "yes", "women":"True", "gender": "prefer not to say"
            }
        }
        //alert(JSON.stringify(payload));
        console.log("payload:" + payload);
        axios.post(SETTINGS.SERVICES_BASE_URL, payload)
          .then((response)=> {
            console.log(response.status)
            alert(JSON.stringify(response.data))
            this.service_list = response.data
            if(this.service_list.length > 0){
                var element = document.getElementById("lucy-form");
                element.classList.add("hide-form");
            }
          }).catch((error) => {
              console.warn('Not good man :(' + error);
          })
    },
    makeQuery(){
      //I need to increment step to see results... lol
      this.step += 1;
      //make sure we return to the top
      this.scrollToTop();
      //And nuke answer
      //this.search_result = this.search_query;
      const payload = {
          "legal_area" : this.search_query.legal_issue,
          //"postcode" : this.search_query.postcode,
          "postcode" : this.myPCode,
          //"demographic_data": {
          //  "asti": "yes", "women":"True", "gender": "prefer not to say" //placeholder for testing....
          //}
          "demographic_data": this.search_query.demo_responses
      }
      console.log("payload: " + JSON.stringify(payload))
      //Now we try and make the API call
      axios.post(SETTINGS.SERVICES_BASE_URL, payload)
        .then((response)=> {
          console.log(response.status)
          //alert(JSON.stringify(response.data))
          this.search_result = response.data
          // if(this.search_result.length > 0){
          //     var element = document.getElementById("lucy-form");
          //     element.classList.add("hide-form");
          // }
        }).catch((error) => {
            console.warn('There was an error accessing the response...' + error);
        })

    },
    restartSearch() {
      this.search_query.legal_issue = "";
      this.search_query.postcode = "";
      this.search_query.demo_responses = [];
      this.myPCode = 0;
      this.legal_issue_display_name = "";
      this.step = 1;
      //make sure we return to the top
      this.scrollToTop();
      //Reset keys to force reset of components... not sure if this is bad practice or not.
      this.page_one_key += 1;
      this.page_two_key += 1;
    },
    printResult() {
      /* force a print window and hide unwanted content. Must be reproduced on all service page */
      document.title='Community legal services';
      window.print();
    },
    stepForm(stepValue) {
      //Move form in appropriate direction
      this.step += stepValue;
      //scroll to top to make sure elements arnt hidden on smaller screens
      this.scrollToTop();
    },
    scrollToTop(){
      window.scrollTo({top: 0 }); //iOS Safari doesn't support the "smooth" behaviour
      //window.scrollTo({top: 0, behavior: 'smooth'}); //when iOS 15 comes out we can revert. 
      //Could add some form of userAgent detection but maybe not worth it.
    }
  }
}
</script>

<style scoped>
  .service-search-wrapper { 
    text-align: left;
    /* max-width: 1240px; */
    /* margin: auto;
    padding: 15px; */
  }
  .submit-step {
    text-align: right;
  }
  .legal-problem-step, 
  .postcode-step,
  .demographic-step  {
    margin-bottom: 30px;
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
    padding: 15px;
    height: 100%;
    border: solid 0.5px;
  }
  .hide-form {
    display: none;
  }






  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
      background-color: #eee;
      color: #444;
      cursor: pointer;
      padding: 18px;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
      /* filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2)); */
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active, .accordion:hover {
      background-color: #ccc;
  }

  /* Style the accordion panel. Note: hidden by default */
  .panel {
      padding: 0 18px;
      background-color: white;
      /* display: none; */
      display: block;
      overflow: hidden;
      /*filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2)); */
      min-height: 200px;
      margin-bottom: 1px;
      padding: 15px;
  }
  .debug--bar {
    /* display: none; */
  }
</style>