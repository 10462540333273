<template>
  <header class="header w-full min-h-[80px]">
    <div class="hide-for-print header-content container">
      <router-link to="/"><h1 class="text-3xl">{{ msg }}</h1></router-link>
    </div>
    <div class="show-for-print header-content container">
        <img src="@/assets/icons/CLCNSW-logo-white-blue-small.png" alt="CLCNSW Logo"  
          class="float-right h-[100px] w-auto"/>
    </div>
  </header>
</template>

<script>


export default {
  name: 'HeaderArea',
  components: {

  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #00A1D4;
}
.header {
    /* height: 100px;
    width: 100%; */
    background-color: white;

}
.header-content {
    /* max-width: 1000px;*/
    margin:auto;
    text-align: left;
    padding: 15px;

}
.header-content h1 {
    color: #00A1D4;
    font-family: "RobotoSlab", Helvetica, Arial;
    font-weight: bold;
/*     font-size: 37pt; */
}
</style>
