<template>
    <!-- Tailindcss style flush accordian Represents a single service as an accordian -->
    <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded bg-white border border-gray-200 mb-2">
        <div class="accordion-header mb-0" v-bind:id="'heading-' + service.id">
            <button class="accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                px-5
                text-base 
                text-gray-800 
                text-left
                bg-white
                border-0
                rounded
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" v-bind:data-bs-target="'#flush-collapse-' + service.id"
                aria-expanded="false" aria-controls="flush-collapseOne">
                <div>
                    <h1 class="text-2xl sm:text-3xl text-black clcnsw-heading">
                        {{ service.service_name }}     
                        <template v-if="service.rank">
                            <span class="hidden">[RANK: {{ service.rank }}]</span><!--remove when approved-->
                        </template>
                    </h1>
                    <template v-if="service.is_hide_parent === false">
                        <p class="text-base sm:text-xl"> {{ service.parent_clc_name }} </p>
                    </template>
                </div>
            </button>
        </div>
        <div v-bind:id="'flush-collapse-' + service.id" class="accordion-collapse border-0 collapse" v-bind:aria-labelledby="'heading-' + service.id" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body py-4 px-5 text-left overflow-hidden">
                <div class="mb-2"  v-if="service.description">
                        <p class="text-base sm:text-xl">{{service.description}}</p>
                </div>

                <!-- <div v-if="service.demographic_note" class="mb-2">
                    <span v-html="service.demographic_note"></span>
                </div> -->

<!--                 <div class="postcodes">
                    <p><strong>Postcodes covered by this service</strong></p>
                    <div class="my-grid flex flex-wrap">
                        <p class="flex-1 mr-2" v-for="pcode in service.postcodes" :key="pcode">{{ pcode }}</p>
                    </div>
                </div> -->
                <!-- <div class="legal-areas mb-5">
                    <p><strong>Legal Services</strong></p>
                    <ul>
                        <li v-for="legal in service.legalAreas" :key="legal">{{ legal.legal_area }}</li>
                    </ul>
                </div> -->

                <!-- PHONE -->
                <div v-if="service.phone_number" class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/Phone.svg" alt="phone" /> 
                    </div>
                    <div class="icon-content text-base">
                        {{ service.phone_number }}
                    </div>
                </div>

                <!-- EAMIL -->
                <div v-if="service.email" class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/Email.svg" alt="email" /> 
                    </div>
                    <div class="icon-content text-base html-overrides">
                        <a :href="'mailto:' + service.email">{{ service.email }}</a>
                    </div>
                </div>

                <!-- WEBSITE -->
                <div v-if="service.url" class="service-icon-wrapper flex flex-grid html-overrides">
                    <div class="icon">
                        <img src="@/assets/icons/Website.svg" alt="website logo" />  
                    </div>
                    <div class="icon-content text-base">
                        <a :href="service.url">{{ service.url }}</a>
                    </div>
                </div>

                <!-- DROP IN -->
<!--                 <div class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/location_or_address.svg" alt="location" />  
                    </div>
                    <div class="icon-content text-base">
                        Drop in/ Location: TODO
                    </div>
                </div> -->

                <!-- OPENING HOURS-->
                <div v-if="service.demographic_note" class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/opening_hours.svg" alt="clock" />
                    </div>
                    <div class="icon-content text-base">
                        <span v-html="service.demographic_note" class="styled-text html-overrides" ></span>
                    </div>
                </div>

                <!-- FACEBOOK-->
<!--                 <div class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/Facebook.svg" alt="facebook" />  
                    </div>
                    <div class="icon-content text-base">
                        Facebook: TODO
                    </div>
                </div> -->

                <!-- ATSI CONTROLLED -->
                <div v-if="service.is_aboriginal_controlled" class="service-icon-wrapper flex flex-grid">
                    <div class="icon">
                        <img src="@/assets/icons/aboriginal_flag.svg" alt="aboriginal-flag" />
                    </div>
                    <div class="icon-content text-base">
                        Aboriginal-controlled organisation
                    </div>
                </div>
                <!-- Optional Notes below -->                    
                <div v-if="service.optional_notes" class="styled-text html-overrides text-base" >
                    <span v-html="service.optional_notes"></span>
                </div>
                <div v-if="service.emergency_notes" class="styled-text html-overrides text-base" >
                    <span v-html="service.emergency_notes"></span>
                </div>            
            
            </div><!-- end accordian body -->
        </div>
  </div>
</template>


<script>


export default {
    name: 'service-card',
    /* take in a single service object and render relevant elements */
    /* setup and create an accordian i basic js... really need to consider a framework. */
    props:{
        service: []
    }
}
</script>

<style scoped>
img {
    width: 100%;
    height: auto;
}
.service-icon-wrapper img {
    display: inline-block;
}
.service-icon-wrapper {
    width:100%;
    min-height: 50px;
    float:left;
}
.service-icon-wrapper .icon {
    width:50px;
    padding: 10px;
    float: left;
}
.service-icon-wrapper .icon-content {
    width:90%;
    padding: 10px;
    float: left;
    padding: 15px;
}

</style>
<style>
.html-overrides a {
    color: blue;
}
</style>


