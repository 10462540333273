import { createApp } from 'vue'
import App from './App.vue'

// Tailwindcss import 
import './styles/app.css';
import 'tw-elements'; //dynamic elements library
import router from './router'
//papaparse import

createApp(App).use(router).mount('#app')
