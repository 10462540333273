<template>
    <div class="search-page-one-wrapper">
        <div class="flex justify-start">
            <h1 class="clcnsw-heading block tracking-wide mb-10"> I have a question about:</h1>   
        </div>
        <div class="w-8/9 m-auto">
            <!-- <pre>{{ questions.legal_questions }}</pre>  for testing-->
            <!-- for each legal q, put Q in button, list in panel -->
            <!-- https://stackoverflow.com/questions/55865058/v-for-without-using-html-element-in-vue-js -->
            <div class="question-row" v-for="lq in legal_questions" :key="lq">

                <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded bg-white border border-gray-200 mb-2">
                    <div class="accordion-header mb-0" v-bind:id="'heading-' + lq.id">
                        <button class="accordion-button
                            collapsed
                            relative
                            flex
                            items-center
                            w-full
                            py-2
                            px-5
                            text-xl text-gray-800 text-left
                            bg-white
                            border-0
                            rounded
                            transition
                            focus:outline-none" type="button" data-bs-toggle="collapse" v-bind:data-bs-target="'#flush-collapse-' + lq.id"
                            aria-expanded="false" aria-controls="flush-collapseOne">
                            <div>
                                <p class="block tracking-wide">{{ lq.question }}</p>
                            </div>
                        </button>
                    </div>
                    <div v-bind:id="'flush-collapse-' + lq.id" class="accordion-collapse border-0 collapse" v-bind:aria-labelledby="'heading-' + lq.id" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body py-4 px-5 text-left">
                        <!-- radio buttons go here -->
                            <div class="legals-list" v-for="item in lq.legalAreas" :key="item.id">
                                <!-- <input class="m-2" type="radio" v-bind:id="item.id" v-bind:value="item.id" @input="$emit('update:legal_query.legal_area', $event.target.value)"/> -->
                                <input 
                                    class="m-2" 
                                    type="radio" 
                                    v-bind:id="lq.id + '-' + item.id" 
                                    v-bind:value="[lq.id + '-' + item.id, item.legal_area]" 
                                    @input="changeLegal_issue" 
                                    v-model="checked_value"/>
                                <label class="m-2 text-base" v-bind:for="lq.id + '-' + item.id">{{ item.legal_area }} </label>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div class="flex justify-center">
               <input 
                    class="m-2" 
                    type="radio" 
                    v-bind:id="0 + '-' + 0" 
                    v-bind:value="['0-0', 'Not sure\, my problem wasn\'t listed']" 
                    @input="changeLegal_issue" 
                    v-model="checked_value"/>
                <label class="m-2 text-base" v-bind:for="0 + '-' + 0">Not sure, my problem wasn't listed</label> 
            </div>
        </div><!-- end legal qs -->
    </div>
</template>


<script>


export default {
    name: 'search-page-one',
    data() {
        return {
            checked_value: ""
        }
    },
    // props: [
    //     "legal_questions",
    //     "legal_query",
    //     //add v-models from parent and then emit them ...
    // ],
    props: {
        legal_issue: String,
        legal_issue_name: String,
        legal_questions: Object,
        legal_query: Object,
        reset: Boolean
    },
    emits: [
        'update:legal_issue',
        'update:legal_issue_name'
    ],
    methods: {
        changeLegal_issue(e){
            // This is cheesy split method for sending up the line both ID and Name of legal matter
            let legal_matter = e.target.value.split(',')
            this.$emit('update:legal_issue', legal_matter[0])
            this.$emit('update:legal_issue_name', legal_matter[1])
            //console.log(legal_matter[1])
        }
    }
}

</script>


