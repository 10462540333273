<template>
<div class="hide-for-print">
    <template v-if="rankedServices.length">
        <div class='services w-full hide-for-print'>
            <div class='results accordion accordion-flush' id="accordionFlushExample">
                <!-- Pass individual object to a ServiceCard -->
                <ServiceCard v-for="service in rankedServices" :key="service" :service="service" />
            </div>
        </div>
    </template>
    <template v-if="!rankedServices.length">
        <div class='services w-full text-center hide-for-print'>
            <p>Unfortunately there are no top matches for your search. Please try a different search or try the more help section below.</p>
        </div>
    </template>
    <div class="show-more-controls w-full border-l-0 border-r-0 border border-gray-200 mb-2 mt-2 p-5 text-center" >
        <p class="mb-1"> If you didnt find what you were looking for above you can see some more centres that could help you below.</p>
        <p class="mb-1"> <strong>Please note</strong>: Many of these centres have specialist intake requirements. Please read their intake notes to see if they can help you.</p> 
        <p class="mb-1 font-bold text-center p-5">
            <span class="hover:cursor-pointer hover:text-blue-600" v-if="showMore == false" @click="showMore = true">Click here to show more results</span>
            <span class="hover:cursor-pointer hover:text-blue-600" v-if="showMore == true" @click="showMore = false">Click here to hide the extra results</span>
        </p>
    </div>
    <template v-if="showMore == true">
        <template v-if="lowerRankServices.length">
            <!-- low ranks -->
<!--             <div>
                <p  class="block tracking-wide text-gray-700 text-xl font-bold mb-1 text-center hide-for-print p-5">MORE HELP</p>
            </div> -->
            <div class='services w-full hide-for-print'>
                <div class='results accordion accordion-flush' id="accordionFlushExample">
                    <!-- Pass individual object to a ServiceCard -->
                    <ServiceCard v-for="service in lowerRankServices" :key="service" :service="service" />
                </div>
            </div>  
        </template>
        <template v-if="!lowerRankServices.length">
            <div class='services w-full hide-for-print text-center'>
                <div class=''>
                   <p>Unfortunately no services have been found for your search.</p>
                </div>
            </div>
        </template>
    </template>

</div>
</template>

<script>
import _ from 'lodash';

import ServiceCard from "../ServiceCard.vue"

export default {
    name: "search-results",
    data() {
        return {
            showMore: false
        }
    },
    components: {
        ServiceCard
    },
    props: {
        search_result: Object,
        search_query: Object
    },
    computed: {
        rankedServices: function () {
            /**
             * This is a placeholder
             * TODO: Pass in proper "sticky" parameter to send all these to the bottom.
             */
            let tmpList = _.orderBy(this.search_result, 'rank', 'desc').filter(service => (service.rank >= 5) ? service: "");
            let index = tmpList.findIndex(e => e.service_name === 'Law Access NSW');
            if (index > -1 /*&& tmpList.length > 1*/) {
                //console.log("Law Access found at index: " + String(index))
                //let e = tmpList.splice(index,1)
                tmpList.push(tmpList.splice(index,1)[0]); //I don't know why [0] works here...
                return tmpList
            } else {
                console.log("Law Access not found, or the only entry")
                return  tmpList;
            }
        },
        lowerRankServices: function(){
            return _.orderBy(this.search_result, 'rank', 'desc').filter(service => (service.rank < 5) ? service: "")
        }
    }
}
</script>
