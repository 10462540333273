<template>
    <div class="w-8/9 m-auto p-5">
        <!-- <div class="w-full">External Data: {{ postcodes }}</div> -->
        <!-- <div class="w-full">Value: {{ selectedPostcode }}</div> -->
        
        <div class="flex justify-center postcode w-8/9">
                <div class="w-full">
                    <label class="block tracking-wide clcnsw-heading mb-5" for="grid-text">
                        Where do you live?
                    </label> 
                    <input 
                        class="appearance-none 
                            block 
                            w-full 
                            
                            text-xl
                            text-gray-700 
                            border 
                            border-gray-200 
                            rounded 
                            py-3 
                            px-4 
                            mb-3 
                            leading-tight 
                            focus:outline-none 
                            focus:bg-white 
                            focus:border-gray-500" 
                        id="postcode"
                        ref="postcode_input" 
                        type="text" 
                        
                        placeholder="Start typing your postcode or suburb"
                        v-model="searchTerm"
                        @focus="postcode_focus = true"
                        @blur="hideDropdown"
                    >
                    <!-- TODO: Fix up styling and have selecton occur... -->
                    <ul v-if="searchLocalities.length" v-show="postcode_focus == true" class="w-full text-left text-black bg-white border border-gray-500 rounded " ref="dropdown">
                        <li class="py-3 px-4">
                            Showing {{ searchLocalities.length }} of {{ postcodes.length }} results
                        </li>
                        <li
                            v-for="location in searchLocalities"
                            :key="location.id"
                            @click="selectPostcode(location); changePostcode(location)"
                            class="search-choice py-3 px-4 hover:drop-shadow-md cursor-pointer"
                            >
                            {{ location.locality }}
                        </li>
                    </ul>
                </div>
        </div>
    </div>
</template>

<script>
import {ref, computed, onMounted, inject } from 'vue';
//import Papa from 'papaparse';
import SETTINGS from '../env.config';
//import myFile from "@/assets/data/postcodes-nsw.csv"
import axios from 'axios';

export default {
  name: "PostCodes",
  setup() {
    let searchTerm = ref('')
    const postcode_focus = ref(false)
    let pcode_url = SETTINGS.POSTCODES_BASE_URL
    let selectedPostcode = ref('')

    const setPostCode = inject('setPostCode')
    const setLocality = inject('setLocality')

    const saveMyPCode = (myCode, myLocal) => {
        setPostCode(myCode)
        setLocality(myLocal)
    }


    const postcodes = ref([])
    onMounted(async () => {
      await axios
          .get(pcode_url)
          .then(response => {
            postcodes.value = response.data
 
          })
    })

    const searchLocalities = computed(() => {
        
        if (searchTerm.value === ''){
            return []
        }

        let matches = 0
    
        return postcodes.value.filter(locality => {
            if (locality.locality.toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 10) {
                matches++
                return locality
            }
        })
    });

    /* selection function to populate form */
    const selectPostcode = (location) => {
        selectedPostcode.value = location.postcode
        searchTerm.value = location.locality
        saveMyPCode(location.postcode, location.locality)
        /* emit here???? */
        /* need to unfocus && reset this field */
    }
    

    return {
        searchTerm,
        searchLocalities,
        postcodes,
        selectPostcode,
        selectedPostcode,
        postcode_focus
    }

  },
  props: {
    postcode: Number
  },
  emits: [
    'update:postcode'
  ],
  components: {
  },
  data() {
    return {
     //postcodes: ['Katoomba NSW, 2780', 'Sydney NSW, 2000', 'Petersham NSW, 2049'],

    };
  },
  methods: {
        changePostcode(e){
            //this.$emit('update:postcode', e.target.value)
            //console.log(e)
            //Need to determine how to nested emit....
            this.$emit('update:postcode', e.postcode)
            console.log("pcode: " + e.postcode)
        },
        
        hideDropdown(){
            /* this is hacky. Adding the timeout gives time for value update in the field before hiding the values... must be a better way */
            setTimeout(this.setStatus, 500);
        },
        setStatus() {
            this.postcode_focus = false;
        }
  }
};
</script>

<style scoped>
.search-choice {
    transition: all .4s ease-in-out;
}
.search-choice:hover {
    /* color: #003A78; */
    transform:scale(1.01);
    background-color: white;
}
.hide-dropdown {
    display: none;
}
</style>
