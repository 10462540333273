<template>
    <div class="search-page-two-wrapper">
        <div class="flex justify-center postcode w-8/9">
            <div class="w-full px-3">
                <PostCodes 
                    @changePostcode="postcodeReEmitemit($event)"
                    :postcode="postcode"
                    
                />
            </div>
        </div>
        <div class="flex justify-center demo-list w-8/9">
            <div class="w-full px-3">
                <!-- the following two lines should be parametized in the backend so they can be configured. Hardcode for now. -->
                <h1 class="clcnsw-heading block tracking-wide" >Do you want to see services for the following groups?</h1>
                <p class="form-check-label inline-block text-gray-500 mb-5 text-base">These answers will help us to provide you with more suitable legal services. These questions are optional.</p>
                    <div class="form-check pl-8" v-for="demo in demographic_questions" :key="demo.id" >
                        <label class="form-check-label inline-block text-xl text-gray-800" for="flexCheckDefault">
                            <input
                                class="-ml-8 form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                type="checkbox" 
                                :id="demo.f_id" 
                                v-bind:value="demo.f_value" 
                                @change="check"
                            />
                            {{ demo.f_label }}
                        </label> 
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import PostCodes from '../PostCodes.vue'

export default {
    name: 'search-page-two',
    components: {
        PostCodes
    },
    props: {
        demographic_questions: Object,
        postcode: Number,
        demo_responses: []
    },
    emits: [
        'update:postcode',
        'update:demo_responses'
    ],
    methods: {
        changePostcode(e){
            this.$emit('update:postcode', e.target.value)
        },
        check(e) {
            //Can't find a better way to do this...
            var myResponses = this.demo_responses
            //Add new value to list
            if (e.target.checked === true){
                console.log("checked")
                myResponses.push(e.target.value);
                //console.log(this.temp_demo_responses);
            }
            //if unchecked, remove by value.
            if (e.target.checked === false){
                console.log("unchecked");
                myResponses = _.pull(myResponses, e.target.value);
            }
            //finally, update the result
            this.$emit('update:demo_responses', myResponses); //this.temp_demo_responses);
        },
        postcodeReEmitemit(e) {
            console.log("EVENT:")
            console.log(e.value)
        }
    }
}

</script>


<style scoped>
/*     .postode {
        margin-bottom: 35px;
        max-width: 600px;
    } */
    .demo-list {
        padding: 15px;
        /* margin-top: 35px; */
    }
</style>