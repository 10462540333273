<template>
  <footer class="hide-for-print footer h-[120px] w-full bottom-0">
    <div class="footer-content container">
        <div class="footer-menu w-1/2">
          <nav class="text-left mb-5 text-base">
            <ul>
              <li ><router-link to="/about" class="mg-2">About community legal centres</router-link></li>
              <li ><router-link to="/privacy" class="mg-2">Terms of use and privacy</router-link></li>
              <li ><router-link to="/services" class="mg-2">View all Services</router-link></li>
              <li ><router-link to="/service-search" class="mg-2">Search for a Service</router-link></li>
              <li ><a href="https://www.lawaccess.nsw.gov.au/" class="mg-2" target="_blank">Contact Law Access NSW</a></li>
            </ul>
          </nav>
          <!-- <a href="#/">Home</a> |
          <a href="#/services">See all services</a> |
          <a href="#/search">Search for help</a> |
          <a href="#/non-existent-path">Bad link test</a> -->
        </div>
        <div class="footer-right w-1/2 text-right float-left">
          <img src="@/assets/icons/CLCNSW-logo-white-blue-small.png" alt="CLCNSW Logo"  class="float-right"/>
        </div>
    </div>
  </footer>
</template>

<script>


export default {
  name: 'FooterArea',
  components: {

  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  width: auto;
  height: 100px;
}
h3 {
  /* margin: 40px 0 0; */
}
ul {
  list-style-type: none;
  /* padding: 0; */
}
li {
  /* display: inline-block; */
  /* margin: 0 10px; */
}
a {
  color: #00A1D4;
}
.footer {
    /* height: 200px; */
    /* width: 100%; */
    background-color: white;

}
.footer-content {
    /* max-width: 1000px; */
    margin: auto;
    text-align: right;
    padding: 15px;
    color: #00A1D4;
}
.footer-menu {
    float:left;
    color: #00A1D4;
}
.footer-menu a {
    color:#003A78;
    font-family: 'Source Sans Pro Light', Arial, Helvetica, sans-serif;
/*     font-size: 25pt; */

}
.footer-menu a:hover {
    color: black;
}

</style>
